var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExcelDownloadHelper = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var XLSX = _interopRequireWildcard(require("xlsx"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var ExcelDownloadHelper = exports.ExcelDownloadHelper = function () {
  function ExcelDownloadHelper() {
    (0, _classCallCheck2.default)(this, ExcelDownloadHelper);
  }
  (0, _createClass2.default)(ExcelDownloadHelper, null, [{
    key: "saveAndOpen",
    value: function () {
      var _saveAndOpen = (0, _asyncToGenerator2.default)(function* (filename, xlsWorkBook) {
        XLSX.writeFileXLSX(xlsWorkBook, `${filename}.xlsx`);
      });
      function saveAndOpen(_x, _x2) {
        return _saveAndOpen.apply(this, arguments);
      }
      return saveAndOpen;
    }()
  }]);
  return ExcelDownloadHelper;
}();