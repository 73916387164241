"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataDB = void 0;
var ODataDB = function () {
  function ODataDB() {
    (0, _classCallCheck2.default)(this, ODataDB);
    this.repos = {};
  }
  (0, _createClass2.default)(ODataDB, [{
    key: "getRepos",
    value: function getRepos(entity) {
      if (this.repos[entity.entityName] == null) {
        this.repos[entity.entityName] = this.addRepos(entity);
      }
      return this.repos[entity.entityName];
    }
  }, {
    key: "addRepos",
    value: function addRepos(entity) {
      var repo = new this.odataRepoClass(entity, this);
      this.repos[entity.entityName] = repo;
      return repo;
    }
  }]);
  return ODataDB;
}();
exports.ODataDB = ODataDB;