import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { TableOdata } from 'materialTheme/src/theme/components/TableOdata';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
import { UserChart } from './UserChart';
export class UsersView extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = false;
        this.tableProps = [
            {
                title: I18n.m.getMessage('firstname'),
                keyInData: 'firstname',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('lastname'),
                keyInData: 'lastname',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('company'),
                keyInData: 'company',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('registered'),
                keyInData: 'createdAt',
                style: { width: 250 },
                dataType: 'DateWithTime',
            },
            {
                title: I18n.m.getMessage('lastLogin'),
                keyInData: 'lastLogin',
                style: { width: 250 },
                dataType: 'DateWithTime',
            },
            {
                title: I18n.m.getMessage('currentSubscriptions'),
                keyInData: 'subscription',
                style: { width: 250 },
                dataType: 'string',
                sortable: false,
            },
            {
                title: I18n.m.getMessage('emailAddress_es'),
                keyInData: 'emails',
                style: { width: 250 },
                dataType: 'string',
            },
        ];
        this.openHistoryDialogSync = () => {
            this.openHistoryDialog().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.openHistoryDialog = async () => {
            LoadingEvents.instance.startLoading();
            try {
                const client = ConnectionHandler.getClient();
                if (client != null) {
                    const users = await client.user.get({
                        select: ['createdAt'],
                    });
                    LoadingEvents.instance.stopLoading();
                    Dialog.instance?.open({
                        showCloseIcon: true,
                        fullscreen: true,
                        content: <UserChart user={users}/>,
                    });
                }
            }
            catch (e) {
                LoadingEvents.instance.stopLoading();
                console.debug('cant get online users', e);
            }
        };
        this.convert = async (user) => {
            let users = [];
            const client = ConnectionHandler.getClient();
            if (client != null) {
                const p = [];
                user.forEach((u) => {
                    p.push(new Promise((resolve) => {
                        RightsManager.hasVersion(u.id)
                            .then((s) => {
                            u['subscription'] = s.toString();
                            if (s === 'enterprise') {
                                client.company
                                    .get({ filter: `users/userId eq '${u.id}'` })
                                    .then((c) => {
                                    if (c.length > 0) {
                                        u.company = c[0].company;
                                    }
                                    resolve(u);
                                })
                                    .catch((err) => {
                                    console.debug('cant get company', err);
                                    resolve(u);
                                });
                            }
                            else {
                                resolve(u);
                            }
                        })
                            .catch((err) => {
                            console.debug('cant get creator', err);
                            resolve(u);
                        });
                    }));
                });
                users = await Promise.all(p);
            }
            return users;
        };
        this.openUser = (user) => {
            Routing.instance.openDialog('user', { id: user.id })(null);
        };
        this.state = {};
    }
    componentDidMount() {
        this.mounted = true;
        if (ConnectionHandler.isPartner) {
            Routing.instance.goTo('/companies', true);
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        const { search } = this.props;
        return (<Page pageTitle={`${I18n.m.getMessage('user')}`} rightButtons={[<Icon toolTip="history" icon="history" key="info" onPress={this.openHistoryDialogSync}/>]}>
        <View style={{
                width: '100%',
                marginTop: 4,
            }}>
          <Card style={{ width: '100%', height: 'auto' }}>
            <TableOdata readModel="User" searchField search={search} convert={this.convert} tableName="Users" maxHeight={ResizeEvent.current.contentHeight - 196} actionItemsLength={0} emptyTableText="" emptyTableHint="" onRowPress={this.openUser} columns={this.tableProps} sortDirection="desc" sortBy="createdAt"/>
          </Card>
        </View>
      </Page>);
    }
}
