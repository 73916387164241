import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigAll } from 'upmesh-client/src/config/ConfigAll';
import './assets/materialTheme.web.css';
import './assets/tui-editor.web.css';
import './assets/upmesh.web.css';
import { unregister } from './registerServiceWorker';
import { SupportApp } from './SupportApp';
ErrorReporter.ENVIRONMENT = ConfigAll.getEnvironment();
ConfigAll.init()
    .then()
    .catch((err) => console.debug('cant init', err))
    .finally(() => {
    if (ConfigAll.getEnvironment() !== 'development') {
        const integrations = [
            new Integrations.CaptureConsole({ levels: ['error'] }),
            new Integrations.Offline({ maxStoredEvents: 100 }),
        ];
        if (ConfigAll.getEnvironment() === 'testing') {
            try {
                integrations.push(new BrowserTracing({
                    beforeNavigate(context) {
                        const s = context.name.split('/');
                        if (s.length > 0) {
                            let newPath = '';
                            for (const p of s) {
                                if (p.length > 0)
                                    newPath += `/${p.includes('-') ? ':id' : p}`;
                            }
                            context.name = newPath.length === 0 ? '/' : newPath;
                        }
                        return context;
                    },
                }));
            }
            catch (e) {
                console.debug('cant set up BrowserTracing for sentry', e);
            }
        }
        Sentry.init({
            dsn: 'https://b1b7dd1e52d848728968e55b476ec864@s.upmesh.de/2',
            tracesSampleRate: 0.1,
            environment: `control_${ErrorReporter.ENVIRONMENT}`,
            release: ConfigAll.getVersion(),
            integrations,
        });
        ErrorReporter.Reporter = Sentry;
    }
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<SupportApp />);
    unregister();
});
