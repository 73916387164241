var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DaySelection = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _DayButton = require("./DayButton");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var DaySelection = exports.DaySelection = function (_PureComponent) {
  (0, _inherits2.default)(DaySelection, _PureComponent);
  var _super = _createSuper(DaySelection);
  function DaySelection(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DaySelection);
    _this = _super.call(this, props);
    _this.getDateInRange = function (day) {
      return _this.checkMinDate(day) && _this.checkMaxDate(day);
    };
    _this.checkMinDate = function (day) {
      var _this$props = Object.assign({}, _this.props),
        viewDate = _this$props.viewDate,
        minDate = _this$props.minDate;
      if (!minDate) return true;
      if (viewDate.getFullYear() < minDate.getFullYear()) return false;
      if (viewDate.getMonth() < minDate.getMonth()) return false;
      if (day < minDate.getDate()) return false;
      return true;
    };
    _this.checkMaxDate = function (day) {
      var _this$props2 = Object.assign({}, _this.props),
        viewDate = _this$props2.viewDate,
        maxDate = _this$props2.maxDate;
      if (!maxDate) return true;
      if (viewDate.getFullYear() > maxDate.getFullYear()) return false;
      if (viewDate.getMonth() > maxDate.getMonth()) return false;
      if (day > maxDate.getDate()) return false;
      return true;
    };
    _this.weekButtons = function () {
      var buttonList = [];
      buttonList = _this.props.weekdaysSchort.map(function (val, index) {
        return (0, _jsxRuntime.jsx)(_DayButton.DayButton, {
          color: "rgba(1, 1, 1, 0.54)",
          text: val[0],
          marginLeft: 4
        }, `${val[0]}_${index.toString()}`);
      });
      if (_CurrentUser.CurrentUser.settings.startWithMonday) {
        var sundayBtn = buttonList.shift();
        if (sundayBtn) {
          buttonList.push(sundayBtn);
        }
      }
      return buttonList;
    };
    _this.getDays = function () {
      var _this$props3 = _this.props,
        coloredDates = _this$props3.coloredDates,
        getDateInRange = _this$props3.getDateInRange,
        viewDate = _this$props3.viewDate,
        selectedDate = _this$props3.selectedDate;
      var buttonList = [];
      var firstDay = new Date(viewDate.getFullYear(), viewDate.getMonth(), 1).getDay();
      var dayAmount = new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 0).getDate();
      if (!_CurrentUser.CurrentUser.settings.startWithMonday) firstDay += 1;
      if (firstDay === 0) firstDay = 7;
      var used = firstDay + dayAmount + 6;
      var weeksInMonth = Math.ceil(used / 7);
      var firstCalWeek = !_CurrentUser.CurrentUser.settings.showKW ? null : _this.getKW(new Date(viewDate.getFullYear(), viewDate.getMonth(), 1));
      var dayList = Array.from({
        length: dayAmount
      }, function (_, key) {
        return key + 1;
      });
      buttonList = [];
      var currentMonth = _this.today.getFullYear() === viewDate.getFullYear() && _this.today.getMonth() === viewDate.getMonth();
      var selectedMonth = selectedDate instanceof Date ? selectedDate.getFullYear() === viewDate.getFullYear() && selectedDate.getMonth() === viewDate.getMonth() : false;
      var range = selectedDate != null && !(selectedDate instanceof Date) && selectedDate.from && selectedDate.to;
      var from = range ? new Date(selectedDate.from.getFullYear(), selectedDate.from.getMonth(), selectedDate.from.getDate(), 12, 0, 0, 0) : new Date();
      var to = range ? new Date(selectedDate.to.getFullYear(), selectedDate.to.getMonth(), selectedDate.to.getDate(), 12, 0, 0, 0) : new Date();
      var _loop = function _loop(i) {
        buttonList.push((0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row'
          },
          children: [firstCalWeek == null ? null : (0, _jsxRuntime.jsx)(_DayButton.DayButton, {
            color: "rgba(1, 1, 1, 0.54)",
            text: (i + firstCalWeek > 52 ? i + firstCalWeek - 52 : i + firstCalWeek).toString(),
            marginRight: 4
          }, `${i}w`), dayList.slice(i === 0 ? 0 : i * 7 + 1 - firstDay, i === 0 ? 8 - firstDay : i * 7 + 8 - firstDay).map(function (value, index) {
            if (value >= 0) {
              var _coloredDates$find;
              var inRange = false;
              if (range) {
                var d = new Date(viewDate.getFullYear(), viewDate.getMonth(), value, 12, 0, 0, 0);
                inRange = d.getTime() >= from.getTime() && d.getTime() <= to.getTime();
                if (d.getTime() === from.getTime()) inRange = 'start';else if (d.getTime() === to.getTime()) inRange = 'end';
              }
              return (0, _jsxRuntime.jsx)(_DayButton.DayButton, {
                text: value.toString(),
                disabled: !getDateInRange({
                  day: value
                }),
                onPress: function onPress(day) {
                  return _this.setDay(day);
                },
                marginLeft: i === 0 && index === 0 ? 'auto' : i === 0 ? 4 : 0,
                marginRight: i === 0 && index === 7 - firstDay && !_CurrentUser.CurrentUser.settings.showKW ? 0 : i === 0 ? 0 : 4,
                current: currentMonth && value === _this.today.getDate(),
                clicked: selectedDate instanceof Date ? selectedMonth && value === selectedDate.getDate() : inRange === 'start' || inRange === 'end',
                inRange: inRange,
                highlightColor: coloredDates == null ? void 0 : (_coloredDates$find = coloredDates.find(function (obj) {
                  return obj.date.getDate() === value && obj.date.getMonth() === viewDate.getMonth() && obj.date.getFullYear() === viewDate.getFullYear();
                })) == null ? void 0 : _coloredDates$find.color
              }, `${value.toString()}_${index.toString()}_${inRange}`);
            }
            return null;
          })]
        }, i));
      };
      for (var i = 0; i < weeksInMonth - 1; i += 1) {
        _loop(i);
      }
      return buttonList;
    };
    _this.setDay = function (day) {
      _this.props.updateSelectedDate(day);
    };
    _this.today = new Date();
    return _this;
  }
  (0, _createClass2.default)(DaySelection, [{
    key: "getKW",
    value: function getKW(date) {
      var doDat = this.getNextThursday(date);
      var kwjahr = doDat.getFullYear();
      var doKW1 = this.getNextThursday(new Date(kwjahr, 0, 4));
      return Math.floor(1.5 + (doDat.getTime() - doKW1.getTime()) / 86400000 / 7);
    }
  }, {
    key: "getNextThursday",
    value: function getNextThursday(date) {
      var thursday = new Date();
      thursday.setTime(date.getTime() + (3 - (date.getDay() + 6) % 7) * 86400000);
      return thursday;
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'column',
          paddingRight: 12,
          paddingLeft: 12
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            justifyContent: _CurrentUser.CurrentUser.settings.showKW ? 'flex-end' : 'flex-start'
          },
          children: this.weekButtons()
        }), this.getDays()]
      });
    }
  }]);
  return DaySelection;
}(_react.PureComponent);