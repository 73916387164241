var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Switch = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _Ripple = require("./utils/Ripple");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var Switch = exports.Switch = function (_PureComponent) {
  (0, _inherits2.default)(Switch, _PureComponent);
  var _super = _createSuper(Switch);
  function Switch(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Switch);
    _this = _super.call(this, props);
    _this.onPress = function (_e) {
      var _this$props = _this.props,
        onPress = _this$props.onPress,
        onChange = _this$props.onChange;
      var value = _this.state.value;
      _this.setState({
        value: !value
      }, function () {
        _this.startAnimation(!value);
        if (onPress != null) {
          onPress(_this.state.value);
        }
        if (onChange != null) {
          onChange(_this.state.value);
        }
      });
    };
    _this.startAnimation = function (toValue) {
      if (_this.sliderAnimation != null) {
        _reactNativeWeb.Animated.timing(_this.sliderAnimation, {
          toValue: toValue === true ? 20 : 0,
          duration: 195,
          useNativeDriver: false
        }).start();
      }
    };
    var value = props.initValue === true ? props.initValue : props.value === true ? props.value : false;
    _this.sliderAnimation = new _reactNativeWeb.Animated.Value(value === true ? 20 : 0);
    _this.state = {
      value: value,
      pressed: false
    };
    return _this;
  }
  (0, _createClass2.default)(Switch, [{
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {}
  }, {
    key: "focus",
    value: function focus() {}
  }, {
    key: "getId",
    value: function getId() {
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.state.value;
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      var _this2 = this;
      var _this$props2 = this.props,
        onPress = _this$props2.onPress,
        onChange = _this$props2.onChange,
        disabled = _this$props2.disabled;
      if (disabled) return;
      this.setState({
        value: value === true
      }, function () {
        _this2.startAnimation(value === true);
        if (onPress != null) {
          onPress(value === true);
        }
        if (onChange != null) {
          onChange(value === true);
        }
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;
      var _this$props3 = this.props,
        disabled = _this$props3.disabled,
        icon = _this$props3.icon;
      var _this$state = this.state,
        value = _this$state.value,
        pressed = _this$state.pressed;
      var color = this.props.color ? this.props.color : _ThemeManager.ThemeManager.style.brandPrimary;
      var bgColor = value === true ? color : _ThemeManager.ThemeManager.style.appBgColorDarken;
      var borderColor = value === true ? color : _ThemeManager.ThemeManager.style.black54;
      var buttonColor = value === true ? '#FFFFFF' : '#5f5f5f';
      var iconColor = value === true ? color : '#ffffff';
      var buttonSize = 24;
      if (!value && icon == null) {
        buttonSize = 16;
      }
      if (pressed) buttonSize = 28;
      if (disabled) {
        bgColor = value === true ? _ThemeManager.ThemeManager.style.appBgColorDarken : _ThemeManager.ThemeManager.style.appBgColor;
        buttonColor = value === true ? '#ffffff' : _ThemeManager.ThemeManager.style.black38;
        borderColor = value === true ? bgColor : _ThemeManager.ThemeManager.style.appBgColorDarken;
        iconColor = value === true ? _ThemeManager.ThemeManager.style.appBgColorDarken : '#ffffff';
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: 32,
          width: 52,
          borderRadius: 16,
          backgroundColor: '#ffffff',
          overflow: 'visible',
          alignItems: 'center',
          justifyContent: 'center'
        },
        children: (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          style: {
            height: 32 - 2 * _ThemeManager.ThemeManager.style.borderWidth,
            width: 52 - 2 * _ThemeManager.ThemeManager.style.borderWidth,
            borderRadius: 16 - _ThemeManager.ThemeManager.style.borderWidth,
            borderWidth: _ThemeManager.ThemeManager.style.borderWidth,
            backgroundColor: bgColor,
            borderColor: borderColor
          },
          onPress: this.onPress,
          disabled: disabled,
          onPressIn: function onPressIn() {
            return _this3.setState({
              pressed: true
            });
          },
          onPressOut: function onPressOut() {
            return _this3.setState({
              pressed: false
            });
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
            style: {
              height: 28,
              width: 28,
              position: 'absolute',
              left: this.sliderAnimation,
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                height: buttonSize,
                width: buttonSize,
                borderRadius: buttonSize / 2,
                backgroundColor: buttonColor
              },
              children: icon != null && icon.length > 0 || value === true && !disabled ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
                icon: icon != null && icon.length > 0 ? icon : 'check',
                color: iconColor,
                toolTip: "",
                outerSize: buttonSize,
                iconSize: buttonSize
              }) : undefined
            })
          })
        })
      });
    }
  }]);
  return Switch;
}(_react.PureComponent);