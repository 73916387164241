"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyEntity = exports.CompanyUserEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var CompanyUserEntity = function (_ReadModelEntity_1$Re) {
  (0, _inherits2.default)(CompanyUserEntity, _ReadModelEntity_1$Re);
  var _super = _createSuper(CompanyUserEntity);
  function CompanyUserEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, CompanyUserEntity);
    _this = _super.call(this, obj);
    _this.entityName = 'CompanyUser';
    _this.fill(obj);
    return _this;
  }
  (0, _createClass2.default)(CompanyUserEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new CompanyUserEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('email', new OdataEntity_1.OdataIndex(true));
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('groupId', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
  return CompanyUserEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.CompanyUserEntity = CompanyUserEntity;
var CompanyEntity = function (_ReadModelEntity_1$Re2) {
  (0, _inherits2.default)(CompanyEntity, _ReadModelEntity_1$Re2);
  var _super2 = _createSuper(CompanyEntity);
  function CompanyEntity(obj) {
    var _this2;
    (0, _classCallCheck2.default)(this, CompanyEntity);
    _this2 = _super2.call(this, obj);
    _this2.entityName = 'Company';
    _this2.fill(obj);
    return _this2;
  }
  (0, _createClass2.default)(CompanyEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new CompanyEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('users', new OdataEntity_1.OdataIndex(true));
      baseMap.set('partnerId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('company', new OdataEntity_1.OdataIndex(true));
      baseMap.set('logo', new OdataEntity_1.OdataIndex(true));
      baseMap.set('contactName', new OdataEntity_1.OdataIndex(true));
      baseMap.set('description', new OdataEntity_1.OdataIndex());
      baseMap.set('street', new OdataEntity_1.OdataIndex());
      baseMap.set('streetNr', new OdataEntity_1.OdataIndex());
      baseMap.set('zip', new OdataEntity_1.OdataIndex());
      baseMap.set('city', new OdataEntity_1.OdataIndex());
      baseMap.set('country', new OdataEntity_1.OdataIndex());
      baseMap.set('phone', new OdataEntity_1.OdataIndex());
      baseMap.set('email', new OdataEntity_1.OdataIndex());
      baseMap.set('partnerCustomerNumber', new OdataEntity_1.OdataIndex(true));
      baseMap.set('customerNumber', new OdataEntity_1.OdataIndex(true));
      baseMap.set('billwerkCustomerNumber', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
  return CompanyEntity;
}(ReadModelEntity_1.ReadModelEntity);
exports.CompanyEntity = CompanyEntity;