import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import { AddUserToUpmeshPartner } from 'upmesh-support-core/src/commands/partner/AddUserToUpmeshPartner';
import { RemoveUserFromUpmeshPartner } from 'upmesh-support-core/src/commands/partner/RemoveUserFromUpmeshPartner';
import { UpmeshPartnerSettingsEntity } from 'upmesh-support-core/src/entities/UpmeshPartnerSettingsEntity';
import { ConnectionHandler } from '../../ConnectionHandler';
import { I18n } from '../../i18n/I18n';
import { ChangePartnerSettingsByPartnerDialog } from './ChangePartnerSettingsByPartnerDialog';
import { ChangePartnerSettingsDialog } from './ChangePartnerSettingsDialog';
import { ChangePartnerUserDialog } from './ChangePartnerUserDialog';
import { PartnerNotes } from './PartnerNotes';
import { PartnerPayments } from './PartnerPayments';
export class PartnerDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onChangeTab = (e) => {
            Routing.instance.changeQueryParameter({ data: { id: this.props.data.id, tab: e.toString() } }, true);
            this.setState({ currentTab: e });
        };
        this.close = (e) => {
            Routing.instance.closeDialog(true)(e);
        };
        this.edit = (e) => {
            Measurement.measure(e.currentTarget)
                .then((p) => {
                const client = {
                    height: p.height,
                    width: p.width,
                    x: p.pageX,
                    y: p.pageY,
                };
                const items = [
                    {
                        onPress: this.editData,
                        text: 'Daten bearbeiten',
                    },
                    {
                        onPress: this.editPrices,
                        text: 'Preise bearbeiten',
                    },
                ];
                Menu.instance?.open({
                    client,
                    items,
                    formInput: false,
                });
            })
                .catch((err) => {
                console.debug('cant open Menu', err);
            });
        };
        this.editData = (e) => {
            Menu.instance?.close();
            Routing.instance.openDialog('partnerEdit', { id: this.props.data.id })(e);
        };
        this.editPrices = (e) => {
            Menu.instance?.close();
            if (ConnectionHandler.isPartner) {
                ChangePartnerSettingsByPartnerDialog.openDialog(this.props.data.id)(e);
            }
            else {
                ChangePartnerSettingsDialog.openDialog(this.props.data.id)(e);
            }
        };
        this.init = () => {
            const { data } = this.props;
            const asyncNow = async () => {
                const client = ConnectionHandler.getClient();
                if (client != null && data != null && data.id != null) {
                    const partner = await client.upmeshPartner.getById(data.id);
                    let settings = new UpmeshPartnerSettingsEntity({ id: data.id });
                    try {
                        settings = await client.upmeshPartnerSettings.getById(data.id);
                    }
                    catch (e) {
                        console.debug('cant get upmesh Partner settings', e);
                    }
                    const userIds = [];
                    if (partner.users != null && partner.users.length > 0) {
                        for (const u of partner.users) {
                            console.log('USER PARTNER', u);
                            userIds.push(`id eq '${u.userId}'`);
                        }
                        const filter = userIds.join(' or ');
                        const users = await client.user.get({ filter });
                        this.setState({ partner, users, settings });
                    }
                    else {
                        this.setState({ partner, settings, users: [] });
                    }
                }
                else {
                    this.setState({ users: [] });
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.addUser = () => {
            this.setState({ addUser: true });
        };
        this.submitAddUserMail = async (email) => {
            LoadingEvents.instance.startLoading();
            try {
                const client = ConnectionHandler.getClient();
                const users = await client?.user.get({ filter: `contains(emails, '${email}') and status ne 'deleted'` });
                if (users == null || users.length === 0) {
                    Routing.instance.alert.post({ text: 'Benutzer nicht gefunden' });
                }
                else if (users.length === 1) {
                    const add = new AddUserToUpmeshPartner({ userId: users[0].userId, role: 'vertrieb' }, this.props.data.id, ConnectionHandler.getConnection().token);
                    await add.execute(ConnectionHandler.getStore());
                    this.setState({ addUser: false }, () => {
                        Routing.instance.alert.post({ text: `${users[0].firstname} ${users[0].lastname} hinzugefügt` });
                    });
                }
                else {
                    Routing.instance.alert.post({ text: `${users.length} Benutzer gefunden` });
                }
            }
            catch (e) {
                const err = e.toString();
                Routing.instance.alert.post({ text: err != null && err.length > 0 ? err : JSON.stringify(e) });
            }
            LoadingEvents.instance.stopLoading();
        };
        this.removeUserNow = (userId) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new RemoveUserFromUpmeshPartner({ userId }, this.props.data.id, ConnectionHandler.getConnection().token);
                remove
                    .execute(ConnectionHandler.getStore())
                    .catch(DefaultErrorHandler.showDefaultErrorAlert)
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.editUser = (userId) => (e) => {
            const { partner } = this.state;
            if (partner == null)
                return;
            ChangePartnerUserDialog.openDialog(partner.id, userId)(e);
        };
        this.removeUser = (userId) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveUser'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeUserNow(userId)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.state = {
            users: [],
            addUser: false,
            partner: props.partner,
            currentTab: props.data.tab != null ? Number.parseInt(props.data.tab, 10) : 0,
        };
    }
    componentDidMount() {
        this.listenerId = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                const e = en.entities.get(this.props.data.id);
                if (e)
                    this.setState({ partner: e.entity }, this.init);
            },
            readModelName: 'UpmeshPartner',
            entityId: this.props.data.id,
        });
        this.listenerIdSettings = ConnectionHandler.getClient()?.eventDispatcher.attach({
            callback: (en) => {
                const e = en.entities.get(this.props.data.id);
                if (e)
                    this.setState({ settings: e.entity }, this.init);
            },
            readModelName: 'UpmeshPartnerSettings',
            entityId: this.props.data.id,
        });
        this.init();
    }
    componentWillUnmount() {
        if (this.listenerId) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('UpmeshPartner', this.listenerId);
        }
        if (this.listenerIdSettings) {
            ConnectionHandler.getClient()?.eventDispatcher.detach('UpmeshPartnerSettings', this.listenerIdSettings);
        }
    }
    formatPrice(value) {
        const e = typeof value === 'string' ? Number.parseFloat(value) : value;
        try {
            if (typeof e === 'number')
                return `${e.toFixed(2)} €`;
            return `${e} €`;
        }
        catch (err) {
            return `${err.toString()} €`;
        }
    }
    renderSettings() {
        const { settings } = this.state;
        if (settings == null)
            return null;
        return (<>
        <View style={PartnerDialog.styles.section}>
          <View style={PartnerDialog.styles.head}>
            <MaterialText type={MaterialTextTypes.H6}>Preise (Verkauf)</MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="sellingPrice">
            <MaterialText centeredBox>
              Projektleiter {settings.sellingItemNumber && ` (${settings.sellingItemNumber})`}
            </MaterialText>
            <MaterialText centeredBox>{this.formatPrice(settings.sellingPrice)}</MaterialText>
          </View>
          <View style={{ ...PartnerDialog.styles.row, backgroundColor: '#FFFFFF' }} key="sellingPriceCollaborator">
            <MaterialText centeredBox>
              Mitarbeiter {settings.sellingItemNumberCollaborator && ` (${settings.sellingItemNumberCollaborator})`}
            </MaterialText>
            <MaterialText centeredBox>{this.formatPrice(settings.sellingPriceCollaborator)}</MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="sellingPriceTimeTracking">
            <MaterialText centeredBox>
              Zeiterfasser {settings.sellingItemNumberTimeTracking && ` (${settings.sellingItemNumberTimeTracking})`}
            </MaterialText>
            <MaterialText centeredBox>{this.formatPrice(settings.sellingPriceTimeTracking)}</MaterialText>
          </View>
        </View>
        <View style={PartnerDialog.styles.section}>
          <View style={PartnerDialog.styles.head}>
            <MaterialText type={MaterialTextTypes.H6}>Preise (Einkauf)</MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="price">
            <MaterialText centeredBox>Projektleiter {settings.itemNumber && ` (${settings.itemNumber})`}</MaterialText>
            <MaterialText centeredBox>
              {settings.pricePercent}% (min. {this.formatPrice(settings.price)})
            </MaterialText>
          </View>
          <View style={{ ...PartnerDialog.styles.row, backgroundColor: '#FFFFFF' }} key="priceCollaborator">
            <MaterialText centeredBox>
              Mitarbeiter {settings.itemNumberCollaborator && ` (${settings.itemNumberCollaborator})`}
            </MaterialText>
            <MaterialText centeredBox>
              {settings.priceCollaboratorPercent}% (min. {this.formatPrice(settings.priceCollaborator)})
            </MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="priceTimeTracking">
            <MaterialText centeredBox>
              Zeiterfasser {settings.itemNumberTimeTracking && ` (${settings.itemNumberTimeTracking})`}
            </MaterialText>
            <MaterialText centeredBox>
              {settings.priceTimeTrackingPercent}% (min. {this.formatPrice(settings.priceTimeTracking)})
            </MaterialText>
          </View>
        </View>
        <View style={PartnerDialog.styles.section}>
          <View style={PartnerDialog.styles.head}>
            <MaterialText type={MaterialTextTypes.H6}>Weiteres</MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="partnerCostumerNumberForUpmesh">
            <MaterialText centeredBox>Upmesh Kundennummer</MaterialText>
            <MaterialText centeredBox>{settings.partnerCostumerNumberForUpmesh}</MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="billingText">
            <MaterialText centeredBox>Abrechnungstext Projektleiter</MaterialText>
            <MaterialText centeredBox>{settings.billingText}</MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="billingTextCollaborator">
            <MaterialText centeredBox>Abrechnungstext Mitarbeiter</MaterialText>
            <MaterialText centeredBox>{settings.billingTextCollaborator}</MaterialText>
          </View>
          <View style={PartnerDialog.styles.row} key="billingTextTimeTracking">
            <MaterialText centeredBox>Abrechnungstext Zeiterfasser</MaterialText>
            <MaterialText centeredBox>{settings.billingTextTimeTracking}</MaterialText>
          </View>
        </View>
      </>);
    }
    render() {
        const { partner, currentTab, settings } = this.state;
        if (partner == null)
            return <Spinner />;
        const tabs = [
            { title: partner.company },
            { title: I18n.m.getMessage('collaborator') },
            { title: I18n.m.getMessage('payments') },
        ];
        if (!ConnectionHandler.isPartner) {
            tabs.push({ title: I18n.m.getMessage('notes') });
        }
        return (<View style={{
                ...ThemeManager.style.absoluteStyle,
            }}>
        <TabBar contentHeight={0} tabs={tabs} width={ResizeEvent.current.windowWidth - 96} onChangedSelected={this.onChangeTab} startSelected={currentTab}/>

        {currentTab === 0 && (<ScrollView style={{ width: '100%', height: '100%' }}>
            <View style={{
                    width: '100%',
                }}>
              <View style={{
                    width: '100%',
                    padding: ThemeManager.style.getScreenRelativePixelSize(16),
                }}>
                <MaterialText type={MaterialTextTypes.Caption}>Id: {partner.id}</MaterialText>
                {settings && settings.customerNumber && (<MaterialText type={MaterialTextTypes.Caption}>Kundennummer: {settings.customerNumber}</MaterialText>)}
                <MaterialText type={MaterialTextTypes.H4}>{`${partner.company}`}</MaterialText>

                <View style={{ height: 16 }}/>

                <MaterialText type={MaterialTextTypes.Caption}>Adresse</MaterialText>
                <MaterialText>{`${partner.street} ${partner.streetNr}`}</MaterialText>
                <MaterialText>{`${partner.zip} ${partner.city}`}</MaterialText>
                <MaterialText>{`${partner.country}`}</MaterialText>

                <View style={{ height: 16 }}/>

                <MaterialText type={MaterialTextTypes.Caption}>Kontakt</MaterialText>
                <MaterialText>{partner.contactName != null ? partner.contactName : '--'}</MaterialText>
                <MaterialText>{`${partner.email}`}</MaterialText>
                <MaterialText>{`${partner.phone}`}</MaterialText>

                <View style={{ height: 16 }}/>

                <MaterialText type={MaterialTextTypes.Caption}>Support</MaterialText>
                <MaterialText>Email-Adresse: {`${partner.supportEmail}`}</MaterialText>
                <MaterialText>Telefon: {`${partner.supportPhone}`}</MaterialText>
              </View>
              {this.renderSettings()}
            </View>
          </ScrollView>)}
        {currentTab === 1 && this.renderUsers()}
        {currentTab === 2 && <PartnerPayments partner={partner} height={ResizeEvent.current.windowHeight - 68}/>}
        {currentTab === 3 && <PartnerNotes partner={partner} height={ResizeEvent.current.windowHeight - 68}/>}
        <View style={{ position: 'absolute', flexDirection: 'row', top: 8, right: 8 }}>
          <Icon icon="pencil-outline" onPress={this.edit} toolTip=""/>
          <View style={{ width: 8 }}/>
          <Icon icon="close" onPress={this.close} toolTip=""/>
        </View>
      </View>);
    }
    renderUsers() {
        const { users, addUser } = this.state;
        const lines = [];
        if (users.length === 0) {
            lines.push(<View style={PartnerDialog.styles.row} key="keine">
          <MaterialText centeredBox>Keine</MaterialText>
        </View>);
        }
        else {
            let i = 0;
            for (const s of users) {
                i += 1;
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
                const user = s;
                lines.push(<View style={{ ...PartnerDialog.styles.row, backgroundColor }} key={user.id}>
            <View style={{ flex: 1 }}>
              <MaterialText fixedWidth="100%" numberOfLines={1}>{`${user.getFullName()}`}</MaterialText>
              <MaterialText fixedWidth="100%" numberOfLines={1}>{`${user.emails?.join(',')}`}</MaterialText>
            </View>
            <View style={{ width: 72, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Icon toolTip="Benutzerrechte bearbeiten" icon="pencil-outline" onPress={this.editUser(user.id)}/>
              <Icon toolTip="Benutzer entfernen" icon="delete-outline" onPress={this.removeUser(user.id)}/>
            </View>
          </View>);
            }
        }
        if (addUser) {
            lines.push(<View style={{ backgroundColor: ThemeManager.style.brandPrimary, padding: 16 }} key="add2">
          <FormInputFilled labelText="E-Mail Adresse" onSubmitEditing={() => {
                    this.submitAddUserMail().catch(DefaultErrorHandler.showDefaultErrorAlert);
                }}/>
        </View>);
        }
        else {
            lines.push(<View style={{ backgroundColor: ThemeManager.style.brandPrimary, alignItems: 'center' }} key="add">
          <Icon icon="plus" color="#FFFFFF" toolTip="Benutzer hinzufügen" onPress={this.addUser}/>
        </View>);
        }
        return (<View style={PartnerDialog.styles.section}>
        <View style={PartnerDialog.styles.head}>
          <MaterialText type={MaterialTextTypes.H6}>Benutzer</MaterialText>
        </View>
        <View style={{ width: '100%' }}>{lines}</View>
      </View>);
    }
}
PartnerDialog.styles = StyleSheet.create({
    section: {
        marginTop: 24,
        padding: 16,
    },
    head: {
        paddingHorizontal: 8,
    },
    row: {
        width: '100%',
        backgroundColor: '#EEEEEE',
        flexDirection: 'row',
        minHeight: 36,
        justifyContent: 'space-between',
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
});
