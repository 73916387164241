import { Card } from 'materialTheme/src/theme/components/Card';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { DefaultErrorHandler } from 'upmesh-client/src/components/DefaultErrorHandler';
import * as uuid from 'uuid';
import { ConnectionHandler } from '../ConnectionHandler';
import { I18n } from '../i18n/I18n';
import { Page } from '../Page';
const bytes = require('bytes');
export class LogsView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.updateOnlineUser = () => {
            this.init().catch((err) => console.debug('cant init', err));
        };
        this.openLog = (e) => {
            Routing.instance.goTo(`/logs/${e.filename}`, true);
            this.loadLogfile(e.filename).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.closeLog = (_e) => {
            Routing.instance.goTo(`/logs`, true);
        };
        this.state = {
            logs: [],
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.init().catch((err) => console.debug('cant init', err));
    }
    async init() {
        try {
            const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/logfiles`, {
                headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
            });
            const o = await f.json();
            const logs = [];
            if (Array.isArray(o)) {
                o.forEach((f) => {
                    logs.push({
                        id: f.filename,
                        filename: f.filename,
                        size: f.size,
                        date: new Date(f.date),
                        serverName: f.filename.split('_')[0],
                    });
                });
            }
            this.setState({ logs }, () => {
                if (this.props.pathvars.file != null) {
                    this.loadLogfile(this.props.pathvars.file).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                }
            });
        }
        catch (e) {
            console.debug('cant get online users', e);
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    async loadLogfile(filename) {
        const f = await fetch(`${ConnectionHandler.apiUrl}/supportroles/logfiles/${filename}`, {
            headers: { authorization: `Bearer ${ConnectionHandler.serverConnection.token}` },
        });
        const o = await f.text();
        const json = o.split('\n');
        const logFile = [];
        json.forEach((row) => {
            try {
                if (row.trim()) {
                    const p = JSON.parse(row.trim());
                    logFile.push({ ...p, id: uuid.v1() });
                }
            }
            catch (err) {
                console.debug('cant convert row to json', err);
            }
        });
        this.setState({ logFile });
    }
    renderFilesTable() {
        const { logs } = this.state;
        return (<Table tableName="LogFiles" maxHeight={ResizeEvent.current.contentHeight - 156} actionItemsLength={0} emptyTableText="" emptyTableHint="" data={logs} onRowPress={this.openLog} columns={[
                {
                    title: 'Dateiname',
                    keyInData: 'filename',
                    style: { width: 400 },
                    sortable: true,
                    dataType: 'string',
                    minWidth: 400,
                },
                {
                    title: 'size',
                    keyInData: 'size',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'number',
                    minWidth: 200,
                    cellRenderer: (item, _column, index) => {
                        const storageUsageInBytes = item.size != null && item.size > 0 ? bytes(item.size) : '-';
                        return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                                flex: 1,
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                height: '100%',
                                paddingHorizontal: 16,
                                minWidth: 200,
                            }}>
                  <MaterialText centeredBox>{storageUsageInBytes}</MaterialText>
                </View>);
                    },
                },
                {
                    title: I18n.m.getMessage('date'),
                    keyInData: 'date',
                    style: { width: 250 },
                    dataType: 'Date',
                },
                {
                    title: 'server',
                    keyInData: 'serverName',
                    style: { width: 250 },
                    dataType: 'string',
                },
            ]}/>);
    }
    renderFileTable() {
        const { logFile } = this.state;
        if (logFile == null)
            return <View />;
        return (<Table tableName="LogFile" maxHeight={ResizeEvent.current.contentHeight - 156} actionItemsLength={0} emptyTableText="" emptyTableHint="" sortBy="timestamp" sortDirection="desc" data={logFile} columns={[
                {
                    title: 'Zeit',
                    keyInData: 'timestamp',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    minWidth: 200,
                    cellRenderer: (item, _column, index) => {
                        const backgroundColor = item.level === 'error'
                            ? ThemeManager.style.brandDanger
                            : item.level === 'warn'
                                ? ThemeManager.style.brandWarning
                                : undefined;
                        return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                                flex: 1,
                                backgroundColor,
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                height: '100%',
                                paddingHorizontal: 16,
                                minWidth: 200,
                            }}>
                  <MaterialText centeredBox>
                    {I18n.m.dateCurrent.localeDateWithTimeString(new Date(item.timestamp), false, true)}
                  </MaterialText>
                </View>);
                    },
                },
                {
                    title: 'Level',
                    keyInData: 'level',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'string',
                    minWidth: 200,
                    cellRenderer: (item, _column, index) => {
                        const backgroundColor = item.level === 'error'
                            ? ThemeManager.style.brandDanger
                            : item.level === 'warn'
                                ? ThemeManager.style.brandWarning
                                : undefined;
                        return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                                flex: 1,
                                backgroundColor,
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                height: '100%',
                                paddingHorizontal: 16,
                                minWidth: 200,
                            }}>
                  <MaterialText centeredBox>{item.level}</MaterialText>
                </View>);
                    },
                },
                {
                    title: 'Service',
                    keyInData: 'service',
                    style: { width: 250 },
                    dataType: 'string',
                },
                {
                    title: 'Meldung',
                    keyInData: 'message',
                    style: { width: 500 },
                    dataType: 'string',
                },
            ]}/>);
    }
    render() {
        const { logs, logFile } = this.state;
        return (<Page pageTitle={`Logs (${logs.length})`}>
        <View style={{
                width: '100%',
                alignItems: 'center',
                alignContent: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 24,
            }}>
          <View style={{
                width: logFile != null ? '50%' : '100%',
            }}>
            <Card style={{ width: '100%', height: 'auto' }}>{this.renderFilesTable()}</Card>
          </View>
          <View style={{
                width: logFile != null ? '50%' : '0%',
            }}>
            <Card style={{ width: '100%', height: 'auto' }}>{this.renderFileTable()}</Card>
          </View>
        </View>
      </Page>);
    }
}
