"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _assertThisInitialized2 = _interopRequireDefault(require("@babel/runtime/helpers/assertThisInitialized"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommandOfflineFirstStore = exports.CommandOfflineFirstStoreConfig = exports.CommandOfflineModel = void 0;
var B64 = require("base-64");
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var ts_events_1 = require("ts-events");
var CommandRemoteStore_1 = require("../../core/commands/CommandRemoteStore");
var EventRootOdata_1 = require("../../core/events/EventRootOdata");
var CommandOfflineRegister_1 = require("./CommandOfflineRegister");
var DatabaseVersion_1 = require("./DatabaseVersion");
var TempFile_1 = require("./TempFile");
var CommandOfflineModel = function (_OdataEntity_1$OdataE) {
  (0, _inherits2.default)(CommandOfflineModel, _OdataEntity_1$OdataE);
  var _super = _createSuper(CommandOfflineModel);
  function CommandOfflineModel(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, CommandOfflineModel);
    _this = _super.call(this, obj);
    _this.entityName = 'CommandOfflineModel';
    if (obj != null) {
      _this.fill(obj);
    }
    return _this;
  }
  (0, _createClass2.default)(CommandOfflineModel, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        return true;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = OdataEntity_1.OdataEntity.createBaseMap();
      baseMap.set('aggregate', new OdataEntity_1.OdataIndex(true));
      baseMap.set('commandName', new OdataEntity_1.OdataIndex(true));
      baseMap.set('entityId', new OdataEntity_1.OdataIndex());
      baseMap.set('data', new OdataEntity_1.OdataIndex());
      baseMap.set('synced', new OdataEntity_1.OdataIndex(true));
      baseMap.set('syncTime', new OdataEntity_1.OdataIndex());
      baseMap.set('error', new OdataEntity_1.OdataIndex());
      baseMap.set('result', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "createOne",
    value: function createOne(obj) {
      return new CommandOfflineModel(obj);
    }
  }]);
  return CommandOfflineModel;
}(OdataEntity_1.OdataEntity);
exports.CommandOfflineModel = CommandOfflineModel;
var CommandOfflineFirstStoreConfig = function (_CommandRemoteStore_) {
  (0, _inherits2.default)(CommandOfflineFirstStoreConfig, _CommandRemoteStore_);
  var _super2 = _createSuper(CommandOfflineFirstStoreConfig);
  function CommandOfflineFirstStoreConfig() {
    var _this2;
    (0, _classCallCheck2.default)(this, CommandOfflineFirstStoreConfig);
    _this2 = _super2.apply(this, arguments);
    _this2.discardOnErrors = 10;
    return _this2;
  }
  return (0, _createClass2.default)(CommandOfflineFirstStoreConfig);
}(CommandRemoteStore_1.CommandRemoteStoreConfig);
exports.CommandOfflineFirstStoreConfig = CommandOfflineFirstStoreConfig;
var CommandOfflineFirstStore = function (_CommandRemoteStore_2) {
  (0, _inherits2.default)(CommandOfflineFirstStore, _CommandRemoteStore_2);
  var _super3 = _createSuper(CommandOfflineFirstStore);
  function CommandOfflineFirstStore(config) {
    var _this3;
    (0, _classCallCheck2.default)(this, CommandOfflineFirstStore);
    _this3 = _super3.call(this, config);
    _this3.onError = new ts_events_1.AsyncEvent();
    _this3._syncing = false;
    _this3._hasToSync = false;
    _this3.abortSync = false;
    _this3.lastUserId = '';
    _this3.currentSyncStatus = {
      percent: 0,
      running: false
    };
    _this3.syncCommands = (0, _asyncToGenerator2.default)(function* () {
      if (_this3.syncAgainTimer != null) clearTimeout(_this3.syncAgainTimer);
      var _assertThisInitialize = (0, _assertThisInitialized2.default)(_this3),
        remoteStore = _assertThisInitialize.remoteStore;
      var connected = remoteStore != null && remoteStore.isConnected;
      if (remoteStore != null && connected && !_this3._syncing && _this3.offlineCommandRepo != null) {
        _this3._syncing = true;
        try {
          var all = yield _this3.offlineCommandRepo.get({
            filter: 'synced ne true',
            orderby: 'createdAt ASC'
          });
          _this3.syncStatusEvent.post({
            percent: 0,
            total: all.length,
            current: 0
          });
          if (all.length > 0) {
            var commands = (0, _toConsumableArray2.default)(all);
            for (var i = 0; i < commands.length; i += 1) {
              var commandModals = all.shift();
              if (commandModals != null) {
                if (_this3.abortSync) {
                  _this3.abortSync = false;
                  break;
                }
                try {
                  var getCommand = CommandOfflineRegister_1.CommandOfflineRegister.getRegisteredCommand(`${commandModals.aggregate}_${commandModals.commandName}`);
                  if (getCommand != null) {
                    var command = getCommand.clone(commandModals.data, commandModals.entityId, _this3.serverConnection ? _this3.serverConnection.token : '');
                    yield command.execute(remoteStore);
                    if (command.executed) {
                      commandModals.syncTime = new Date();
                      commandModals.synced = true;
                      commandModals.error = false;
                      yield _this3.offlineCommandRepo.patch(commandModals);
                    }
                  }
                } catch (e) {
                  commandModals.error = true;
                  commandModals.errorCounter = commandModals.errorCounter != null ? commandModals.errorCounter + 1 : 1;
                  commandModals.result = JSON.stringify(e);
                  if (_this3.config.discardOnErrors === -1 || commandModals.errorCounter < _this3.config.discardOnErrors) yield _this3.offlineCommandRepo.patch(commandModals);
                }
              }
              var percent = Math.round(i / commands.length * 100);
              _this3.syncStatusEvent.post({
                percent: percent,
                total: all.length,
                current: i
              });
            }
          }
        } catch (e) {}
        _this3._hasToSync = false;
        _this3._syncing = false;
      } else {
        try {
          if (_this3.remoteStore != null && _this3.offlineCommandRepo != null) {
            var counts = yield _this3.offlineCommandRepo.count('synced ne true');
            if (counts > 0) {
              _this3.syncAgainTimer = setTimeout(function () {
                _this3.syncCommands().catch(function (err) {
                  return void 0;
                });
              }, 2000);
            }
          }
        } catch (e) {}
      }
      _this3.syncStatusEvent.post({
        percent: 100,
        current: 0,
        total: 0
      });
      try {
        if (_this3.config != null && _this3.config.syncDispatcher() != null && _this3.config.databaseVersion != null) {
          var _this3$config$syncDis = _this3.config.syncDispatcher(),
            db = _this3$config$syncDis.db,
            dbOptions = _this3$config$syncDis.dbOptions,
            userId = _this3$config$syncDis.userId,
            backendUrl = _this3$config$syncDis.backendUrl;
          if (db != null && userId && dbOptions) {
            yield _this3.resetDbIfNeeded(db, dbOptions, _this3.config.databaseVersion, backendUrl);
          }
        }
      } catch (e) {}
    });
    _this3.abortSyncCommands = (0, _asyncToGenerator2.default)(function* () {
      if (_this3._syncing) {
        _this3.abortSync = true;
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return !_this3._syncing;
        });
      }
    });
    _this3.eventHandler = config.eventHandler;
    _this3.syncStatusEvent = new ts_events_1.AsyncEvent();
    var serverConnection = _this3.config.serverConnection;
    if (_this3.config.syncDispatcher) _this3.config.syncDispatcher().registerCommandSync(_this3.syncCommands, _this3.abortSyncCommands, _this3.syncStatusEvent, serverConnection);
    if (_this3.remoteStore == null) _this3.remoteStore = new CommandRemoteStore_1.CommandRemoteStore(new CommandRemoteStore_1.CommandRemoteStoreConfig(config.serverConnection, _this3.config.dispatcher));
    return _this3;
  }
  (0, _createClass2.default)(CommandOfflineFirstStore, [{
    key: "offlineCommandRepo",
    get: function get() {
      var _this$config$syncDisp;
      return this.config.syncDispatcher && ((_this$config$syncDisp = this.config.syncDispatcher().db) == null ? void 0 : _this$config$syncDisp.getRepos(new CommandOfflineModel()));
    }
  }, {
    key: "isSyncing",
    get: function get() {
      return this._syncing || this._hasToSync;
    }
  }, {
    key: "syncing",
    get: function get() {
      return this._syncing;
    }
  }, {
    key: "hasToSync",
    get: function get() {
      return this._hasToSync;
    }
  }, {
    key: "save",
    value: function () {
      var _save = (0, _asyncToGenerator2.default)(function* (command) {
        var _this4 = this;
        var timedout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var offlineUsage = false;
        if (this.remoteStore != null && this.remoteStore.isConnected) {
          try {
            return yield this.remoteStore.save(command);
          } catch (e) {
            if (e['messageCode'] !== 'noConnection') throw e;
          }
        }
        offlineUsage = CommandOfflineRegister_1.CommandOfflineRegister.isRegistered(command) && this.offlineCommandRepo != null;
        if (offlineUsage) {
          var oc = CommandOfflineRegister_1.CommandOfflineRegister.getRegisteredCommand(`${command.aggregate}_${command.commandName}`);
          if (oc != null) {
            var c = oc.clone(command.data, command.entityId, command.token);
            var userId = '';
            if (c.token != null && c.token.length > 0) {
              try {
                var bValue = JSON.parse(B64.decode(c.token.split('.')[1]));
                userId = bValue._id;
              } catch (e2) {}
            }
            if (this.remoteStore != null && this.offlineCommandRepo != null) {
              var entity = new CommandOfflineModel();
              entity.commandName = c.commandName;
              entity.entityId = c.entityId;
              entity.aggregate = c.aggregate;
              entity.data = c.data;
              entity.error = false;
              yield this.offlineCommandRepo.post(entity);
              this._hasToSync = true;
            }
            yield c.canI();
            yield c.validate();
            var e = yield c.createEvents();
            this.postEvents(e, userId);
            return null;
          }
        }
        if (timedout) {
          throw {
            messageCode: 'commandOfflineNotPossible',
            message: 'Die Aktion kann offline nicht ausgeführt werden'
          };
        } else {
          return new Promise(function (resolve, reject) {
            setTimeout(function () {
              _this4.save(command, true).then(function (r) {
                resolve(r);
              }).catch(function (e) {
                _this4.onError.post(e);
                reject(e);
              });
            }, 3000);
          });
        }
      });
      function save(_x2) {
        return _save.apply(this, arguments);
      }
      return save;
    }()
  }, {
    key: "resetDbIfNeeded",
    value: function () {
      var _resetDbIfNeeded = (0, _asyncToGenerator2.default)(function* (localDb, dbOptions, neededVersion, backendUrl) {
        yield localDb.initDB(dbOptions);
        var dvRepo = localDb.getRepos(new DatabaseVersion_1.DatabaseVersion());
        var currentVersions = yield dvRepo.get();
        var currentVersion = currentVersions.length > 0 ? currentVersions[0] : new DatabaseVersion_1.DatabaseVersion({
          currentVersion: neededVersion
        });
        var lastReset = currentVersion.currentVersion;
        if (lastReset == null || lastReset < neededVersion) {
          var online = false;
          if (backendUrl != null) {
            try {
              var apiOnline = yield fetch(`${backendUrl}/health`);
              var state = yield apiOnline.text();
              online = state === 'started';
            } catch (e) {}
          }
          var tempfiles = [];
          var offlineCommands = [];
          if (online) {
            var offlineCommandRepo = localDb.getRepos(new CommandOfflineModel());
            if (offlineCommandRepo && (yield offlineCommandRepo.count()) > 0) {
              var g = yield offlineCommandRepo.get();
              offlineCommands = (0, _toConsumableArray2.default)(g);
            }
            var repo = localDb.getRepos(new TempFile_1.TempFile());
            if (repo && (yield repo.count()) > 0) {
              var _g = yield repo.get();
              tempfiles = (0, _toConsumableArray2.default)(_g);
            }
            yield localDb.deleteDB();
            yield localDb.initDB(dbOptions);
            var dvRepo2 = localDb.getRepos(new DatabaseVersion_1.DatabaseVersion());
            currentVersion.currentVersion = neededVersion;
            yield dvRepo2.postOrPatchMany([currentVersion]);
            yield localDb.saveDB();
            if (offlineCommands.length > 0) {
              var _offlineCommandRepo = localDb.getRepos(new CommandOfflineModel());
              for (var c of offlineCommands) {
                try {
                  if (_offlineCommandRepo) yield _offlineCommandRepo.post(c);
                } catch (e) {}
              }
            }
            if (tempfiles.length > 0) {
              var _repo = localDb.getRepos(new TempFile_1.TempFile());
              for (var t of tempfiles) {
                try {
                  if (_repo) yield _repo.post(t);
                } catch (e) {}
              }
            }
          }
        }
      });
      function resetDbIfNeeded(_x3, _x4, _x5, _x6) {
        return _resetDbIfNeeded.apply(this, arguments);
      }
      return resetDbIfNeeded;
    }()
  }, {
    key: "postEvents",
    value: function postEvents(e, createdBy) {
      if (Array.isArray(e)) {
        for (var event of e) {
          this.eventHandler.post(new EventRootOdata_1.EventRootOdata(Object.assign({}, event, {
            createdBy: createdBy
          })));
        }
      } else {
        this.eventHandler.post(new EventRootOdata_1.EventRootOdata(Object.assign({}, e, {
          createdBy: createdBy
        })));
      }
    }
  }]);
  return CommandOfflineFirstStore;
}(CommandRemoteStore_1.CommandRemoteStore);
exports.CommandOfflineFirstStore = CommandOfflineFirstStore;