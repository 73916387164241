import { MessagesDe as MessagesDeClient } from 'upmesh-client/build/src/i18n/MessagesDe';
export class MessagesDe extends MessagesDeClient {
    constructor() {
        super(...arguments);
        this.active = 'aktiv';
        this.activeSubscriptions = 'Aktive Abos';
        this.admin = 'Administrator';
        this.amount = 'Anzahl';
        this.archived = 'archiviert';
        this.cantEditLicenseAlreadyPayments = 'Es bestehen bereits Zahlungsforderungen, deswegen kann die Lizenz nicht mehr geändert werden';
        this.companies = 'Firmen';
        this.company = 'Firma';
        this.companyCustomers = 'Firmenkunden';
        this.contact = 'Kontakt';
        this.createdAt = 'erstellt';
        this.creator = 'Eigentümer';
        this.currentSubscriptions = 'aktuelles Abo';
        this.dashboard = 'Dashboard';
        this.deleted = 'gelöscht';
        this.emailAddress_es = 'E-Mail Adresse(n)';
        this.emailaddress = 'E-Mail Adresse';
        this.end = 'Ende';
        this.firstname = 'Vorname';
        this.lastLogin = 'letzer Login';
        this.lastModifiedAt = 'letzte Änderung';
        this.lastname = 'Nachname';
        this.logout = 'abmelden';
        this.moduleMaps = 'Karten';
        this.moduleTimeTracking = 'Zeiterfassung';
        this.moduleUpmeshKi = 'Upmesh Ki - Meshy ( BETA nicht auf Production aktivieren )';
        this.notes = 'Notizen';
        this.page = 'Seite';
        this.partner = 'Partner';
        this.payments = 'Zahlungsmanagement';
        this.plans = 'Pläne';
        this.project = 'Projekt';
        this.projects = 'Projekte';
        this.registered = 'registriert';
        this.size = 'Größe';
        this.start = 'Start';
        this.status = 'Status';
        this.subscriptions = 'Abos';
        this.supportRole = 'Support';
        this.tickets = 'Tickets';
        this.type = 'Typ';
        this.upmesh = 'upmesh';
        this.user = 'Benutzer';
        this.userOrCompany = 'User oder Firma';
        this.version = 'version';
        this.willBeLoggedIn = 'Sie werden eingeloggt...';
        this.wrongCredentials = 'Zugangsdaten nicht korrekt';
    }
}
