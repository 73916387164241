var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BottomSheet = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _SlideAnimationTiming = require("./animations/SlideAnimationTiming");
var _Overlay = require("./Overlay");
var _Toast = require("./Toast");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
var BOTTOMSHEET_OPENING = 'opening';
var BOTTOMSHEET_OPENED = 'opened';
var BOTTOMSHEET_CLOSING = 'closing';
var BOTTOMSHEET_CLOSED = 'closed';
var BottomSheet = exports.BottomSheet = function (_PureComponent) {
  (0, _inherits2.default)(BottomSheet, _PureComponent);
  var _super = _createSuper(BottomSheet);
  function BottomSheet(props) {
    var _this;
    (0, _classCallCheck2.default)(this, BottomSheet);
    _this = _super.call(this, props);
    _this.animationRunning = false;
    _this._onLayoutView = function (_event) {
      _this.forceUpdate();
    };
    _this.close = function () {
      if (BottomSheet.instance != null) {
        if (!BottomSheet.instance.animationRunning) {
          if (BottomSheet.instance.animationRunningTO != null) {
            clearTimeout(BottomSheet.instance.animationRunningTO);
          }
          BottomSheet.instance.closeNow();
        } else {
          BottomSheet.instance.animationRunningTO = setTimeout(function () {
            var _BottomSheet$instance;
            (_BottomSheet$instance = BottomSheet.instance) == null ? void 0 : _BottomSheet$instance.close();
          }, 200);
        }
      }
    };
    _this.closeNow = function () {
      if (_Toast.Toast.isOpened) {
        _Toast.Toast.restartTimeout();
      }
      var onClosed = _this.props.onClosed;
      _this.setbottomsheetState(0, onClosed);
    };
    _this.onOverlayPress = function () {
      var closeOnTouchOutside = _this.state.closeOnTouchOutside;
      if (closeOnTouchOutside) {
        if (_this.state.closeFunction != null) {
          _this.state.closeFunction();
        } else {
          _this.closeNow();
        }
      }
    };
    _this.state = BottomSheet.defaultState;
    return _this;
  }
  (0, _createClass2.default)(BottomSheet, [{
    key: "bottomSheetSize",
    get: function get() {
      var _this$state = this.state,
        maxWidth = _this$state.maxWidth,
        maxHeight = _this$state.maxHeight,
        width = _this$state.width,
        height = _this$state.height;
      var _this$state2 = this.state,
        windowWidth = _this$state2.windowWidth,
        windowHeight = _this$state2.windowHeight;
      if (width != null && width > 0.0 && width <= 1.0) {
        width *= windowWidth;
      }
      if (height != null && height > 0.0 && height <= 1.0) {
        height *= windowHeight;
      }
      if (maxWidth != null && maxWidth > 0.0 && maxWidth <= 1.0) {
        maxWidth *= windowWidth;
      }
      if (maxHeight != null && maxHeight > 0.0 && maxHeight <= 1.0) {
        maxHeight *= windowHeight;
      }
      if (maxHeight > windowHeight) {
        maxHeight = windowHeight;
      }
      if (maxWidth > windowWidth) {
        maxWidth = windowWidth;
      }
      if (width > maxWidth) {
        width = maxWidth;
      }
      if (height > maxHeight) {
        height = maxHeight;
      }
      if (windowWidth <= _ThemeManager.ThemeManager.style.breakpointM) {
        maxWidth = width = _ResizeEvent.ResizeEvent.current.windowWidth;
      }
      if (height > windowHeight - _ThemeManager.ThemeManager.style.headerHeight) {
        height = windowHeight - _ThemeManager.ThemeManager.style.headerHeight;
      }
      return {
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        width: width === 0 ? 'auto' : width,
        height: height === 0 ? 'auto' : height,
        paddingBottom: _ResizeEvent.ResizeEvent.current.contentBottom
      };
    }
  }, {
    key: "bottomsheetState",
    get: function get() {
      return this.state.bottomsheetState;
    }
  }, {
    key: "pointerEvents",
    get: function get() {
      if (this.props.overlayPointerEvents != null) {
        return this.props.overlayPointerEvents;
      }
      return this.state.bottomsheetState === BOTTOMSHEET_OPENED ? 'box-none' : 'none';
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      BottomSheet._instance = this;
      var open = this.props.open;
      if (open === true) this.openNow();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState, _snapshot) {
      if (this.props.open !== prevProps.open) {
        if (this.props.open === true) {
          this.openNow();
        } else {
          this.close();
        }
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) _reactNativeWeb.Keyboard.dismiss();
    }
  }, {
    key: "hideOverlay",
    value: function hideOverlay() {
      this.setState({
        showOverlay: false
      });
    }
  }, {
    key: "open",
    value: function open(state) {
      var _this2 = this;
      var openImmediately = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (BottomSheet.instance != null) {
        _reactNativeWeb.Keyboard.dismiss();
        if (!BottomSheet.instance.animationRunning) {
          if (BottomSheet.instance.animationRunningTO != null) {
            clearTimeout(BottomSheet.instance.animationRunningTO);
          }
          var newState = Object.assign(BottomSheet.defaultState, state);
          BottomSheet.instance.bottomsheetAnimation = new _SlideAnimationTiming.SlideAnimation();
          BottomSheet.instance.setState(newState, function () {
            if (openImmediately) {
              _this2.openNow();
            }
          });
        } else {
          BottomSheet.instance.animationRunningTO = setTimeout(function () {
            _this2.open(state, openImmediately);
          }, 200);
        }
      } else {
        setTimeout(function () {
          _this2.open(state, openImmediately);
        }, 200);
      }
    }
  }, {
    key: "openNow",
    value: function openNow() {
      if (_Toast.Toast.isOpened) {
        _Toast.Toast.resetTimeout();
      }
      var onOpened = this.props.onOpened;
      this.setbottomsheetState(1, onOpened);
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state3 = this.state,
        bottomsheetState = _this$state3.bottomsheetState,
        showOverlay = _this$state3.showOverlay,
        content = _this$state3.content,
        closeOnTouchOutside = _this$state3.closeOnTouchOutside,
        scrollable = _this$state3.scrollable,
        elevation = _this$state3.elevation;
      if (this.state.bottomsheetState === BOTTOMSHEET_CLOSED) {
        return null;
      }
      var _this$props = this.props,
        overlayPointerEvents = _this$props.overlayPointerEvents,
        overlayBackgroundColor = _this$props.overlayBackgroundColor,
        overlayOpacity = _this$props.overlayOpacity,
        bottomsheetStyle = _this$props.bottomsheetStyle,
        animationDuration = _this$props.animationDuration;
      var windowHeight = _ResizeEvent.ResizeEvent.current.windowHeight;
      var windowWidth = _ResizeEvent.ResizeEvent.current.windowWidth;
      var bottomsheetSize = this.bottomSheetSize;
      var hidden = bottomsheetState === BOTTOMSHEET_CLOSED ? _ThemeManager.ThemeManager.style.dialogHidden : {};
      var isShowOverlay = [BOTTOMSHEET_OPENING, BOTTOMSHEET_OPENED].includes(bottomsheetState);
      var dimensions = {
        width: windowWidth,
        height: windowHeight - _ResizeEvent.ResizeEvent.current.contentTop,
        top: _ResizeEvent.ResizeEvent.current.contentTop,
        justifyContent: 'flex-end'
      };
      var V = scrollable ? _reactNativeWeb.ScrollView : _reactNativeWeb.View;
      var zIndex = elevation != null ? {
        elevation: elevation,
        zIndex: elevation
      } : undefined;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        pointerEvents: !showOverlay ? 'box-none' : 'auto',
        style: [_ThemeManager.ThemeManager.style.dialogContainer, hidden, dimensions, Object.assign({
          alignItems: 'center'
        }, zIndex)],
        children: [showOverlay ? (0, _jsxRuntime.jsx)(_Overlay.Overlay, {
          width: windowWidth,
          height: windowHeight,
          pointerEvents: overlayPointerEvents,
          showOverlay: isShowOverlay,
          onPress: closeOnTouchOutside ? this.onOverlayPress : undefined,
          backgroundColor: overlayBackgroundColor,
          opacity: overlayOpacity,
          animationDuration: animationDuration
        }) : null, (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          onLayout: this._onLayoutView,
          pointerEvents: !showOverlay ? 'box-none' : 'auto',
          style: [_ThemeManager.ThemeManager.style.dialogBackground, bottomsheetSize, bottomsheetStyle, this.bottomsheetAnimation != null ? this.bottomsheetAnimation.animations : null, _ThemeManager.ThemeManager.style.elevation24, Object.assign({
            overflow: 'hidden'
          }, zIndex)],
          children: (0, _jsxRuntime.jsx)(V, {
            style: zIndex,
            children: content != null ? content : null
          })
        })]
      });
    }
  }, {
    key: "setbottomsheetState",
    value: function setbottomsheetState(toValue) {
      var _this3 = this;
      var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      if (!this.animationRunning) {
        this.animationRunning = true;
        var bottomsheetState = toValue > 0 ? BOTTOMSHEET_OPENING : BOTTOMSHEET_CLOSING;
        if (this.bottomsheetAnimation != null && this.bottomsheetAnimation.toValue != null) {
          this.setState({
            bottomsheetState: bottomsheetState
          }, function () {
            _this3.bottomsheetAnimation.toValue(toValue, function () {
              if (bottomsheetState === BOTTOMSHEET_CLOSING) {
                _this3.setState(BottomSheet.defaultState, function () {
                  _this3.animationRunning = false;
                  callback();
                });
              } else {
                _this3.setState({
                  bottomsheetState: BOTTOMSHEET_OPENED
                }, function () {
                  _this3.animationRunning = false;
                  callback();
                });
              }
            });
          });
        } else if (bottomsheetState === BOTTOMSHEET_CLOSING) {
          this.setState(BottomSheet.defaultState, function () {
            _this3.animationRunning = false;
            callback();
          });
        } else {
          this.setState({
            bottomsheetState: BOTTOMSHEET_OPENED
          }, function () {
            _this3.animationRunning = false;
            callback();
          });
        }
      } else {
        clearTimeout(this.animationRunningTO);
        this.animationRunningTO = setTimeout(function () {
          _this3.setbottomsheetState(toValue, callback);
        }, this.props.animationDuration);
      }
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        animationDuration: 150,
        windowWidth: 1,
        windowHeight: 1,
        haveOverlay: true,
        closeFunction: function closeFunction() {},
        open: false
      };
    }
  }, {
    key: "defaultState",
    get: function get() {
      return {
        closeFunction: function closeFunction() {
          var _BottomSheet$instance2;
          return (_BottomSheet$instance2 = BottomSheet.instance) == null ? void 0 : _BottomSheet$instance2.close();
        },
        saveTitle: 'save',
        bottomsheetState: BOTTOMSHEET_CLOSED,
        showOverlay: true,
        width: 500,
        height: 0,
        maxWidth: 0.9,
        maxHeight: 0.9,
        content: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {}),
        closeOnTouchOutside: true,
        scrollable: false,
        windowHeight: _ResizeEvent.ResizeEvent.current.windowHeight,
        windowWidth: _ResizeEvent.ResizeEvent.current.windowWidth
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (BottomSheet._instance != null) {
        return BottomSheet._instance;
      }
      return null;
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps) {
      return {
        windowHeight: nextProps.size.windowHeight,
        windowWidth: nextProps.size.windowWidth
      };
    }
  }]);
  return BottomSheet;
}(_react.PureComponent);